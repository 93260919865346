<template>
  <div>
    <p>
      I have to emphasise that this information on this map is incomplete and
      likely to be (in some details at least) inaccurate. It is a start, not an
      end, of an attempt to keep this part of our past in our memory. In
      particular, the street location of each murder is inexact, as I was
      working off place names, not street names.
    </p>
    <p>
      The events behind the deaths remain, to a large extent, obscure. What
      protests, marches, organising happened in these times? Lynn helped source
      on note of clarification: towards the end of 1976 the Apartheid regime
      fomented violence between migrants (living in migrant worker hostels) and
      their surrounding communities, leading to the horrify violence of December
      1976 and January 1976. An account of that violence can be found
      <a
        href="https://github.com/pvanheus/1976/blob/master/RoleOfRiotPoliceInBurningsAndKillingsNyangaCapeTownChristmas1976.pdf"
        >here</a
      >.
    </p>
  </div>
</template>

<script>
export default {
  name: "Header",
};
</script>

<style scoped></style>

<template>
  <div class="credit">
    Favicon features icons made by
    <a href="https://www.freepik.com/" title="Freepik">Freepik</a>
    from
    <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a> is
    licensed by
    <a
      href="http://creativecommons.org/licenses/by/3.0"
      title="Creative Commons BY 3.0"
      target="_blank"
      >CC 3.0 BY</a
    >
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
.credit {
  text-align: center;
  font-size: 10px;
  padding: 20px;
}
</style>

import { render, staticRenderFns } from "./DeathPopupContent.vue?vue&type=template&id=c2f14618&scoped=true&"
import script from "./DeathPopupContent.vue?vue&type=script&lang=js&"
export * from "./DeathPopupContent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2f14618",
  null
  
)

export default component.exports
<template>
  <div>
    <p>
      June 16 1976 marks the start of the 1976 youth uprising against Apartheid
      in South Africa. Most closely associated with the school students march in
      Soweto and the murder of Hector Pieterson, the revolt spread across the
      country and well past June 1976. Along with the revolt came the deaths,
      most often of those shot by police. The South African History Online
      website has tried to collect a
      <a href="http://www.sahistory.org.za/topic/june-16-soweto-youth-uprising"
        >list</a
      >
      of the "casualties of 1976". I took that list, did a little cleanup (code
      is
      <a href="https://github.com/pvanheus/1976">here</a>) and produced a
      <a href="http://pvanheus.github.io/1976/1976_gen.html">map</a>.
    </p>

    <p>
      That map was the start of my engagement with Lynn Abrahams of Iziko
      Museum. With her help an updated list of the casualties in the Western
      Cape was produced, which is the basis of the map below. The community at
      <a href="https://codebridge.org.za/">Codebridge</a> community evenings
      also contributed invaluable technical guidance and encouragement.
    </p>
  </div>
</template>

<script>
export default {
  name: "Header",
};
</script>

<style scoped></style>
